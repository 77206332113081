import { Component, OnInit } from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  tournages = [
    {
      src: this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/x8rhzCdRszI")
    },
    {
      src: this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/02AmuuAPMIk")
    },
    {
      src: this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/zXrruvZKsos")
    },
    {
      src: this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/3enMrIELtGQ")
    }

  ];

  constructor(public sanitizer:DomSanitizer) {}

  ngOnInit(): void {
  }

}
