<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<footer>
  <div class="content">
    <div class="column1">
      <p>INVEST'IMMO & CO  &copy; 2021. ALL RIGHTS RESERVED</p>
    </div>
    <div class="column2">
      <a routerLink="mention-legal" class="nav-link"><p>Mention Légales</p></a>
    </div>
    <div class="column3">
      <a rel="noopener" class="social-icon" href="https://www.instagram.com/lamaisonshooting/?hl=fr" target="_blank">
        <i class="fa fa-instagram"></i>
      </a>
      <a rel="noopener" class="social-icon" href="https://fr-fr.facebook.com/pages/category/Real-Estate-Agent/La-Maison-Shooting-2152082088351726/" target="_blank">
        <i class="fa fa-facebook"></i>
      </a>
    </div>
  </div>
</footer>
