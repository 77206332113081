<!DOCTYPE html>
<html lang="fr">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="theme-color" content="#e6e6e6"/>
    <meta name="robots" content="all">

    <title>Contact</title>
    <meta name="description" content="Vous voulez contacter la Maison Shooting ou nous envoyer une demande ?">
</head>

<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<body>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
          <div class="card card-back my-5">
            <div class="card-body">
              <h1>Contact</h1>
              <p>Si vous recherchez un bel endroit pour vos shootings et vos tournages: <p>
              <p style="font-weight: bold;">La Maison Shooting</p>
              <p>se tient à votre disposition pour vous offrir un joli cadre tranquille et lumineux.</p>
              <p>N'hésitez pas à me contacter si vous voulez des renseignements pour vos projets:</p>
              <p style="text-decoration: underline;"><a href="mailto:syl@sylea.pro">syl@sylea.pro</a></p>
              <div style="margin-top: 2em">
                <p class="text-center">Suivez notre actualité et contactez-nous par message privé en cliquant sur: </p>
                  <div class="div-social-icons">
                    <a href="https://fr-fr.facebook.com/pages/category/Real-Estate-Agent/La-Maison-Shooting-2152082088351726/" class="social-icon social-icon--facebook">
                      <i class="fa fa-facebook"></i>
                      <div class="tooltip">Facebook</div>
                    </a>
                    <a href="https://www.instagram.com/lamaisonshooting/?hl=fr" class="social-icon social-icon--instagram">
                      <i class="fa fa-instagram"></i>
                      <div class="tooltip">Instagram</div>
                    </a>
                </div>
              </div>
              <div style="margin-top: 70px">
                <p style="font-weight: bold;">Total de visiteurs: {{visitorCounter}}</p>
              </div>
              <div style="margin-top: 70px">
                <h1>Page de contact</h1>
              </div>
              <div class="container">
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                  <div class='row'>
                    <div class="column1">
                      <label class="labels" for="name">Nom et Prénom</label>
                      <input formControlName="name" id="name" name="name" placeholder="Nom et prénom" required>
                    </div>
                    <div class="column2">
                      <label class="labels" for="phone">Téléphone</label>
                      <input formControlName="phone" id="phone" name="phone" placeholder="Téléphone" required>
                      <div *ngIf="contactForm.controls['phone'].invalid && (contactForm.controls['phone'].dirty || contactForm.controls['phone'].touched)" class="alert alert-danger">
                        <div>
                          Vous devez seulement écrire des chiffres
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class="column1">
                      <label class="labels" for="mail">Adresse mail</label>
                      <input formControlName="mail" id="mail" name="mail" placeholder="Mail" required>
                      <div *ngIf="contactForm.controls['mail'].invalid && (contactForm.controls['mail'].dirty || contactForm.controls['mail'].touched)" class="alert alert-danger">
                        <div>
                          Une adresse mail valide est requise
                        </div>
                      </div>
                    </div>
                    <div class="column2">
                      <label class="labels" for="project">Projet</label>
                      <select formControlName="project" id="project" name="project">
                        <option value="" hidden>Projet</option>
                        <option value="Shooting photo">Shooting photo</option>
                        <option value="Clip musical">Clip musical</option>
                        <option value="Film publicitaire">Film publicitaire</option>
                        <option value="Film cinéma">Film cinéma</option>
                    </select>
                    </div>
                  </div>
                  <div class='row'>
                    <div class="column1">
                    <label class="labels" for="nbrPerson">Nombre de personnes</label>
                    <input formControlName="nbrPerson" id="nbrPerson" name="nbrPerson" placeholder="Ex: 5" required>
                    <div *ngIf="contactForm.controls['nbrPerson'].invalid && (contactForm.controls['nbrPerson'].dirty || contactForm.controls['nbrPerson'].touched)" class="alert alert-danger">
                      <div>
                        Vous devez seulement écrire des chiffres
                      </div>
                    </div>
                  </div>
                  <div class="column2">
                    <label class="labels" for="nbrHour">Nombre d'heures</label>
                    <input formControlName="nbrHour" id="nbrHour" name="nbrHour" placeholder="Ex: 2H" required>
                    <div *ngIf="contactForm.controls['nbrHour'].invalid && (contactForm.controls['nbrHour'].dirty || contactForm.controls['nbrHour'].touched)" class="alert alert-danger">
                      <div>
                        Vous devez seulement écrire des chiffres et un H
                      </div>
                    </div>
                  </div>
                </div>
                <label class="labels" for="brief">Commentaire</label>
                <textarea formControlName="brief" id="brief" name="brief" placeholder="Votre commentaire"></textarea>
                <div *ngIf="alertRecaptchaFail" style="display: flex; align-items: center; justify-content: center;" class="alert alert-danger alert-dismissible fade show" role="alert">
                  <h6>Vous devez valider le recaptcha avant d'envoyer le formulaire de contact</h6>
                  <button (click)="closeAlert()" type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div *ngIf="alertFail" style="display: flex; align-items: center; justify-content: center;" class="alert alert-danger alert-dismissible fade show" role="alert">
                  <h6>Une erreur est survenue lors de l'envoi du formulaire de contact</h6>
                  <button (click)="closeAlert()" type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div *ngIf="alertSuccess" style="display: flex; align-items: center; justify-content: center;" class="alert alert-success alert-dismissible fade show" role="alert">
                  <h6>Le formulaire de contact a été envoyé avec succès</h6>
                  <button (click)="closeAlert()" type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form [formGroup]="aFormGroup" class="recaptcha">
                  <ngx-recaptcha2 #captchaElem
                    [siteKey]="siteKeyRecaptcha"
                    [useGlobalDomain]="false"
                    [hl]="recaptchaLang"
                    (reset)="recaptchaReaload()"
                    (expire)="recaptchaReaload()"
                    (success)="recaptchaSuccess()"
                    formControlName="recaptcha">
                  </ngx-recaptcha2>
                </form>
                <div class="text-center btnContainer">
                  <button type="submit" [disabled]="contactForm.invalid" class="btn btn-primary">Envoyer</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
