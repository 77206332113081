<!DOCTYPE html>
<html lang="fr">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Maison shooting</title>
</head>

<nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">

    <a routerLink="accueil" data-toggle="collapse" data-target="#navbarResponsive.show"><img alt="Logo-maison-shooting" class="navbar-brand" src="../../assets/logo/logo.png"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
            <li [routerLinkActive]="['active']" class="nav-item">
                <a routerLink="accueil" data-toggle="collapse" data-target="#navbarResponsive.show" class="nav-link">Accueil</a>
            </li>
            <li [routerLinkActive]="['active']" class="nav-item">
                <a routerLink="maison" data-toggle="collapse" data-target="#navbarResponsive.show" class="nav-link">Maison</a>
            </li>
            <li [routerLinkActive]="['active']" class="nav-item">
                <a routerLink="modèles" data-toggle="collapse" data-target="#navbarResponsive.show" class="nav-link">Modèles</a>
            </li>
            <li [routerLinkActive]="['active']" class="nav-item">
                <a routerLink="tournages" data-toggle="collapse" data-target="#navbarResponsive.show" class="nav-link">Tournages</a>
            </li>
            <li [routerLinkActive]="['active']" class="nav-item">
                <a routerLink="contact" data-toggle="collapse" data-target="#navbarResponsive.show" class="nav-link">Contact</a>
            </li>
        </ul>
    </div>
</nav>

</html>
