<!DOCTYPE html>
<html lang="fr">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="theme-color" content="#e6e6e6"/>
    <meta name="robots" content="all">

    <title>Les photos des modèles dans la Maison Shooting</title>
    <meta name="description" content="Les photos des modèles au salon, à l'extérieur, dans la cuisine, dans les escaliers, dans la salle de bain et dans la chambre de la Maison Shooting">
</head>

<head>
  <link rel="preconnect" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css2?family=Allura&display=swap" rel="stylesheet">
</head>
<body>
  <div style="margin-top: 100px !important">
  </div>
  <h1>Salon</h1>
  <div class="gallery-image">
    <div *ngFor="let salonModel of imagesSalonModel; let i=index" class="img-box">
      <img alt="Modèles-salon-maison-shooting-paris-{{i + 1}}" loading="lazy" src="../../assets/images/modèles/salon/resize/salon-model{{i + 1}}-380px.jpg"/>
      <div class="transparent-box" (click)="openSalonModel(i)">
        <div class="caption">
          <p>{{salonModel.caption}}</p>
        </div>
      </div>
    </div>
  </div>
  <h1>Extérieur</h1>
  <div class="gallery-image">
    <div *ngFor="let outsideModel of imagesOutsideModel; let i=index" class="img-box">
      <img alt="Modèles-extérieur-maison-shooting-paris-{{i + 1}}" loading="lazy" src="../../assets/images/modèles/exterieur/resize/exterieur-model{{i + 1}}-380px.jpg"/>
      <div class="transparent-box" (click)="openOutsideModel(i)">
        <div class="caption">
          <p>{{outsideModel.caption}}</p>
        </div>
      </div>
    </div>
  </div>
  <h1>Escalier</h1>
  <div class="gallery-image">
    <div *ngFor="let stairsModel of imagesStairsModel; let i=index" class="img-box">
      <img alt="Modèles-escalier-maison-shooting-paris-{{i + 1}}" loading="lazy" src="../../assets/images/modèles/escalier/resize/escalier-model{{i + 1}}-380px.jpg"/>
      <div class="transparent-box" (click)="openStairsModel(i)">
        <div class="caption">
          <p>{{stairsModel.caption}}</p>
        </div>
      </div>
    </div>
  </div>
  <h1>Cuisine</h1>
  <div class="gallery-image">
    <div *ngFor="let kitchenModel of imagesKitchenModel; let i=index" class="img-box">
      <img alt="Modèles-cuisine-maison-shooting-paris-{{i + 1}}" loading="lazy" src="../../assets/images/modèles/cuisine/resize/cuisine-model{{i + 1}}-380px.jpg"/>
      <div class="transparent-box" (click)="openKitchenModel(i)">
        <div class="caption">
          <p>{{kitchenModel.caption}}</p>
        </div>
      </div>
    </div>
  </div>
  <h1>Salle de bain</h1>
  <div class="gallery-image">
    <div *ngFor="let bathroomModel of imagesBathroomModel; let i=index" class="img-box">
      <img alt="Modèles-salle-de-bain-maison-shooting-paris-{{i + 1}}" loading="lazy" src="../../assets/images/modèles/salle-de-bain/resize/salle-de-bain-model{{i + 1}}-380px.jpg"/>
      <div class="transparent-box" (click)="openBathroomModel(i)">
        <div class="caption">
          <p>{{bathroomModel.caption}}</p>
        </div>
      </div>
    </div>
  </div>
  <h1>Chambre</h1>
  <div class="gallery-image">
    <div *ngFor="let bedroomModel of imagesBedroomModel; let i=index" class="img-box">
      <img alt="Modèles-chambre-maison-shooting-paris-{{i + 1}}" loading="lazy" src="../../assets/images/modèles/chambre/resize/chambre-model{{i + 1}}-380px.jpg"/>
      <div class="transparent-box" (click)="openBedroomModel(i)">
        <div class="caption">
          <p>{{bedroomModel.caption}}</p>
        </div>
      </div>
    </div>
  </div>
  <div style="margin-top: 150px">
  </div>
</body>
