import { Component, OnInit } from '@angular/core';

import { Lightbox } from 'ngx-lightbox';

import { Images } from '../../models/images.model'

@Component({
  selector: 'app-house',
  templateUrl: './house.component.html',
  styleUrls: ['./house.component.scss']
})
export class HouseComponent implements OnInit {

  imagesSalon: Images[] = [];
  imagesOutside: Images[] = [];
  imagesKitchen: Images[] = [];
  imagesBedroom: Images[] = [];

  imageSalonNum = 11;
  imageOutsideNum = 12;
  imageKitchenNum = 3;
  imageBedroomNum = 2;

  constructor(private _lightbox: Lightbox) {
    //ImagesSalon
    for (let i = 1; i <= this.imageSalonNum; i++) {
      const src = '../../assets/images/maison/salon/salon' + i + '.jpg';
      const caption = i + '/' + this.imageSalonNum;
      const thumb = "";
      const image = new Images(src, caption, thumb);

      this.imagesSalon.push(image);
    }

    //ImagesOutside
    for (let i = 1; i <= this.imageOutsideNum; i++) {
      const src = '../../assets/images/maison/exterieur/exterieur' + i + '.jpg';
      const caption = i + '/' + this.imageOutsideNum;
      const thumb = "";
      const image = new Images(src, caption, thumb);

      this.imagesOutside.push(image);
    }

    //ImagesKitchen
    for (let i = 1; i <= this.imageKitchenNum; i++) {
      const src = '../../assets/images/maison/cuisine/cuisine' + i + '.jpg';
      const caption = i + '/' + this.imageKitchenNum;
      const thumb = "";
      const image = new Images(src, caption, thumb);

      this.imagesKitchen.push(image);
    }

    //ImagesBedroom
    for (let i = 1; i <= this.imageBedroomNum; i++) {
      const src = '../../assets/images/maison/chambre/chambre' + i + '.jpg';
      const caption = i + '/' + this.imageBedroomNum;
      const thumb = "";
      const image = new Images(src, caption, thumb);

      this.imagesBedroom.push(image);
    }
  }

  ngOnInit(): void {
  }

  openSalon(index: number): void {
    this._lightbox.open(this.imagesSalon, index);
  }

  openOutside(index: number): void {
    this._lightbox.open(this.imagesOutside, index);
  }

  openKitchen(index: number): void {
    this._lightbox.open(this.imagesKitchen, index);
  }

  openBedroom(index: number): void {
    this._lightbox.open(this.imagesBedroom, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}
