import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CounterService } from "../services/counter.service";

import "./../../assets/js/smtp.js";
declare let Email: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  visitorCounter: Number = 0;

  //For local test
  //siteKeyRecaptcha: string = "6LeiSXgaAAAAAPJU3KzvfIxpkdH_AGmhxHF3bHe6";

  siteKeyRecaptcha: string = "6LfJ6ngaAAAAAGq8XQ9RNziRtd51Nbu4g7ptwWCI";

  recaptchaLang: string = "fr";

  recaptchaValidate: boolean = false;

  alertRecaptchaFail: boolean = false;
  alertFail: boolean = false;
  alertSuccess: boolean = false;

  aFormGroup: FormGroup = this.formBuilder.group({recaptcha: ['', Validators.required]});

  contactForm: FormGroup = this.formBuilder.group({
                            name: ['', Validators.required],
                            phone: ['', [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
                            mail: ['', [Validators.required, Validators.email]],
                            project: ['', Validators.required],
                            nbrPerson: ['', [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
                            nbrHour: ['', [Validators.required, Validators.pattern('[-_hH0-9]*')]],
                            brief: [''],
                          });

  constructor(private formBuilder: FormBuilder, private counterService: CounterService) {
    this.recaptchaReaload();
    this.Counter();
  }

  async Counter()
  {
    this.visitorCounter = Number(await this.counterService.getCounter());
  }

  ngOnInit(): void {
  }

  onSubmit() {
    const name = this.contactForm.value['name'];
    const phone = this.contactForm.value['phone'];
    const mail = this.contactForm.value['mail'];
    const project = this.contactForm.value['project'];
    const nbrPerson = this.contactForm.value['nbrPerson'];
    const nbrHour = this.contactForm.value['nbrHour'];
    const brief = this.contactForm.value['brief'];

    if (this.recaptchaValidate == false) {
      this.alertSuccess = false;
      this.alertRecaptchaFail = true;
    }
    else {
      Email.send({
        Host: "smtp.gmail.com",
        Username: "ronanbouziane1@gmail.com",
        Password: "osldbteybamzitmq",
        To: 'syl@sylea.pro',
        From: "ronanbouziane1@gmail.com",
        Subject: "Formulaire de contact La Maison Shooting",
        Body: "<i>Formulaire de contact La Maison Shooting.</i> <br /> <br/> <b>Nom: </b> " + name + " <br /> <br/> <b>Téléphone: </b> " + phone + "<br /> <br/> <b>Email: </b> " + mail + "<br /> <br/> <b>Projet: </b> " + project + "<br /> <br/> <b>Nombres de personnes: </b> " + nbrPerson + "<br /> <br/> <b>Nombre d'heures: </b> " + nbrHour + "<br /> <br/> <b>Commentaire: </b> " + brief + "<br /> <br><br> <b>~Fin du formulaire de contact.~</b> "
      }).then(
        (message: any) => {
          if (message == "OK") {
            this.emailIsSend()
            this.contactForm.reset();
          }
          else {
            this.emailFailed()
          }
        }
      )
    }
  }

  emailIsSend()
  {
    this.alertRecaptchaFail = false;
    this.alertFail = false;
    this.alertSuccess = true;
  }

  emailFailed()
  {
    this.alertRecaptchaFail = false;
    this.alertFail = true;
    this.alertSuccess = false;
  }

  recaptchaReaload()
  {
    this.recaptchaValidate = false;
  }

  recaptchaSuccess()
  {
    this.recaptchaValidate = true;
  }

  closeAlert()
  {
    this.alertRecaptchaFail = false;
    this.alertFail = false;
    this.alertSuccess = false;
  }
}
