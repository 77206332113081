import { Component, OnInit } from '@angular/core';

import { Lightbox } from 'ngx-lightbox';

import { Images } from '../../models/images.model'

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss']
})
export class ModelComponent implements OnInit {

  imagesSalonModel: Images[] = [];
  imagesOutsideModel: Images[] = [];
  imagesStairsModel: Images[] = [];
  imagesKitchenModel: Images[] = [];
  imagesBathroomModel: Images[] = [];
  imagesBedroomModel: Images[] = [];

  imageSalonModelNum = 21;
  imageOutsideModelNum = 18;
  imageStairsModelNum = 8;
  imageKitchenModelNum = 4;
  imageBathroomModelNum = 3;
  imageBedroomModelNum = 7;

  nameSalon: String[] = [
    "@mimiicarreira",
    "@stanzeflasher, @syleamode",
    "@francoisbrie @solangewally",
    "@stanzeflasher",
    "@syleamode",
    "@fabtagphoto, @leana_antunes",
    "@milano_since_2016, @augustinphotographies, @japonnoir_model, @_mr.fernandes_",
    "@ellesensuellefrance",
    "@charlestompkins, @syleamode",
    "@milano_since_2016, @augustinphotographies",
    "@fabtagphoto, @leana_antunes",
    "@janus.dolt",
    "@fabtagphoto, @leana_antunes",
    "@milano_since_2016, @michaelleho, @_mr.fernandes_",
    "@emmanuellaforguephotographe, @dorisrouesne",
    "@janus.dolt",
    "@poulain.florent, @maryss-juice, @tyraxaiden",
    "@norbertdamico",
    "@michaelleho, @japonnoir_model",
    "",
    "@justanillusionboudoir, @maelineinkeedgirls"
  ]

  nameOutside: String[] = [
    "@delaisementphillipe, @macha.chervyakova",
    "@edgar.deli, @joulain_laura_model",
    "@charlestompkins, @syleamode",
    "@mendes.leny, @syleamode",
    "@delaisementphillipe, @macha.chervyakova",
    "@emma_wax40, @syleamode",
    "@edgar.deli, @joulain_laura_model",
    "@edgar.deli, @joulain_laura_model",
    "",
    "@charlestompkins, @syleamode",
    "@bylau971, @_marinemodele__",
    "@ellesensuellefrance, @kimie_flore",
    "@ellesensuellefrance",
    "@milano_since_2016, @augustinphotographies, @japonnoir_model",
    "@fabtagphoto, @leana_antunes",
    "@pixsandra",
    "",
    "@augustinphotographies"
  ]

  nameStairs: String[] = [
    "@t.guillemin.photographe, @anaellemorice",
    "@phillipe_fifi, @emmalacambre_model",
    "@charlestompkins, @syleamode",
    "@norbertdamico",
    "@justanillusionboudoir, @maelineinkeedgirls",
    "@fabtagphoto, @leana_antunes",
    "@thierryguillemin",
    "@mimiicarreira"
  ]

  nameKitchen: String[] = [
    "@justanillusionboudoir, @maelineinkeedgirls",
    "@justanillusionboudoir, @maelineinkeedgirls",
    "@augustinphotographies",
    "@poulain.florent, @maryss-juice, @tyraxaiden"
  ]

  nameBathroom: String[] = [
    "@norbertdamico",
    "@norbertdamico",
    "@norbertdamico"
  ]

  nameBedroom: String[] = [
    "@ellesensuellefrance, @joulain_laura_model",
    "@norbertdamico",
    "@_patrick_pierre_, @theredfoxx_",
    "@milano_since_2016",
    "@_patrick_pierre_, @theredfoxx_",
    "@milano_since_2016",
    "@thierryguillemin"
  ]

  constructor(private _lightbox: Lightbox) {
    //ImagesSalonModel
    for (let i = 1; i <= this.imageSalonModelNum; i++) {
      const src = '../../assets/images/modèles/salon/salon-model' + i + '.jpg';
      const caption = i + '/' + this.imageSalonModelNum + ' ' + this.nameSalon[i - 1];
      const thumb = "";
      const image = new Images(src, caption, thumb);

      this.imagesSalonModel.push(image);
    }

    //ImagesOutsideModel
    for (let i = 1; i <= this.imageOutsideModelNum; i++) {
      const src = '../../assets/images/modèles/exterieur/exterieur-model' + i + '.jpg';
      const caption = i + '/' + this.imageOutsideModelNum + ' ' + this.nameOutside[i - 1];
      const thumb = "";
      const image = new Images(src, caption, thumb);

      this.imagesOutsideModel.push(image);
    }

    //ImagesStairsModel
    for (let i = 1; i <= this.imageStairsModelNum; i++) {
      const src = '../../assets/images/modèles/escalier/escalier-model' + i + '.jpg';
      const caption = i + '/' + this.imageStairsModelNum + ' ' + this.nameStairs[i - 1];
      const thumb = "";
      const image = new Images(src, caption, thumb);

      this.imagesStairsModel.push(image);
    }

    //ImagesKitchenModel
    for (let i = 1; i <= this.imageKitchenModelNum; i++) {
      const src = '../../assets/images/modèles/cuisine/cuisine-model' + i + '.jpg';
      const caption = i + '/' + this.imageKitchenModelNum + ' ' + this.nameKitchen[i - 1];
      const thumb = "";
      const image = new Images(src, caption, thumb);

      this.imagesKitchenModel.push(image);
    }

    //ImagesBathroomModel
    for (let i = 1; i <= this.imageBathroomModelNum; i++) {
      const src = '../../assets/images/modèles/salle-de-bain/salle-de-bain-model' + i + '.jpg';
      const caption = i + '/' + this.imageBathroomModelNum + ' ' + this.nameBathroom[i - 1];;
      const thumb = "";
      const image = new Images(src, caption, thumb);

      this.imagesBathroomModel.push(image);
    }

    //ImagesBedroomModel
    for (let i = 1; i <= this.imageBedroomModelNum; i++) {
      const src = '../../assets/images/modèles/chambre/chambre-model' + i + '.jpg';
      const caption = i + '/' + this.imageBedroomModelNum + ' ' + this.nameBedroom[i - 1];
      const thumb = "";
      const image = new Images(src, caption, thumb);

      this.imagesBedroomModel.push(image);
    }
  }

  ngOnInit(): void {
  }

  openSalonModel(index: number): void {
    this._lightbox.open(this.imagesSalonModel, index);
  }

  openOutsideModel(index: number): void {
    this._lightbox.open(this.imagesOutsideModel, index);
  }

  openStairsModel(index: number): void {
    this._lightbox.open(this.imagesStairsModel, index);
  }

  openKitchenModel(index: number): void {
    this._lightbox.open(this.imagesKitchenModel, index);
  }

  openBathroomModel(index: number): void {
    this._lightbox.open(this.imagesBathroomModel, index);
  }

  openBedroomModel(index: number): void {
    this._lightbox.open(this.imagesBedroomModel, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}
