<!DOCTYPE html>
<html lang="fr">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="theme-color" content="#e6e6e6"/>
    <meta name="robots" content="all">

    <title>Les photos de la Maison Shooting</title>
    <meta name="description" content="Les photos du salon, de l'extérieur, de la cuisine et de la chambre de la Maison Shooting">
</head>

<head>
  <link rel="preconnect" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css2?family=Allura&display=swap" rel="stylesheet">
</head>
<body>
  <div style="margin-top: 100px !important">
  </div>
  <h1>Salon</h1>
  <div class="gallery-image">
    <div *ngFor="let salon of imagesSalon; let i=index" class="img-box">
      <img alt="Le-salon-maison-shooting-paris-{{i + 1}}" loading="lazy" src="../../assets/images/maison/salon/resize/salon{{i + 1}}-380px.jpg"/>
      <div class="transparent-box" (click)="openSalon(i)">
        <div class="caption">
          <p>{{salon.caption}}</p>
        </div>
      </div>
    </div>
  </div>

  <h1>Extérieur</h1>
  <div class="gallery-image">
    <div *ngFor="let outside of imagesOutside; let i=index" class="img-box">
      <img alt="L'extérieur-maison-shooting-paris-{{i + 1}}" loading="lazy" src="../../assets/images/maison/exterieur/resize/exterieur{{i + 1}}-380px.jpg"/>
      <div class="transparent-box" (click)="openOutside(i)">
        <div class="caption">
          <p>{{outside.caption}}</p>
        </div>
      </div>
    </div>
  </div>

  <h1>Cuisine</h1>
  <div class="gallery-image">
    <div *ngFor="let kitchen of imagesKitchen; let i=index" class="img-box">
      <img alt="La-cuisine-maison-shooting-paris-{{i + 1}}" loading="lazy" src="../../assets/images/maison/cuisine/resize/cuisine{{i + 1}}-380px.jpg"/>
      <div class="transparent-box" (click)="openKitchen(i)">
        <div class="caption">
          <p>{{kitchen.caption}}</p>
        </div>
      </div>
    </div>
  </div>

  <h1>Chambre</h1>
  <div class="gallery-image">
    <div *ngFor="let bedroom of imagesBedroom; let i=index" class="img-box">
      <img alt="La-chambre-maison-shooting-paris-{{i + 1}}" loading="lazy" src="../../assets/images/maison/chambre/resize/chambre{{i + 1}}-380px.jpg"/>
      <div class="transparent-box" (click)="openBedroom(i)">
        <div class="caption">
          <p>{{bedroom.caption}}</p>
        </div>
      </div>
    </div>
  </div>
  <div style="margin-top: 150px">
  </div>
</body>
