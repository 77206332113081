<!DOCTYPE html>
<html lang="fr">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="theme-color" content="#e6e6e6"/>
    <meta name="robots" content="all">

    <title>Les tournages dans la Maison Shooting</title>
    <meta name="description" content="Vidéo des différents tournages réalisés dans la Maison Shooting">
</head>

<body>
    <div class="container">
      <h1 class="center">Tournages</h1>
  <div class="gallery-image">
     <div *ngFor="let tournage of tournages;" class="col-md-6 my-2">
        <div class="video embed-responsive embed-responsive-16by9">
          <iframe title="Vidéo du tournage" class="embed-responsive-item" width="auto" height="400px" [src]=tournage.src></iframe>
        </div>
      </div>
    </div>
  </div>
  <div style="margin-top: 150px">
  </div>
</body>
