import { Component } from '@angular/core';

import firebase from 'firebase/app';
import 'firebase/storage'
import 'firebase/analytics'

import { LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'MaisonShooting';

  constructor(public _lightboxConfig: LightboxConfig) {
    const firebaseConfig = {
      apiKey: "AIzaSyDpbb70XMtD16pPCvUzJBo3WwiweZbdtqg",
      authDomain: "lamaisonshooting.firebaseapp.com",
      databaseURL: "https://lamaisonshooting-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "lamaisonshooting",
      storageBucket: "lamaisonshooting.appspot.com",
      messagingSenderId: "294092575760",
      appId: "1:294092575760:web:5399688066523649a16695",
      measurementId: "G-Q63C0ZKY9D"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    _lightboxConfig.centerVertically = true;
    _lightboxConfig.fadeDuration = 0.5;
    _lightboxConfig.alwaysShowNavOnTouchDevices = true;
    _lightboxConfig.wrapAround = true;
    _lightboxConfig.disableScrolling = true;
    _lightboxConfig.enableTransition = true;
  }
}
