import { Injectable } from '@angular/core';
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'

@Injectable()
export class CounterService {

  counter: String = "";
  newCounter: number = 0;
  asToIncrement: boolean = true;

  incrementCounter()
  {
    if (this.asToIncrement == true)
    {
      this.newCounter = Number(this.counter) + 1;
      this.counter = this.newCounter.toString();
    }
  }

  saveCounter()
  {
    if (this.asToIncrement == true)
    {
      firebase.database().ref('/counter').set(this.counter);
    }
  }

  getCounter()
  {
    return new Promise (
      (resolve, reject) => {
        firebase.database().ref('/counter').once('value').then(
          (data) => {
            this.asToIncrement = true;
            this.counter = data.val();
            resolve(data.val());
          }, () => {
            this.asToIncrement = false;
            reject();
          }
        )
      }
    )
  }
}
