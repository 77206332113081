import { Component, OnInit } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

import { CounterService } from "../services/counter.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private cookieService: CookieService, private counterService: CounterService) {
    if (cookieService.check('AsAlreadyVisitSite') == false)
    {
      this.Counter();
    }
  }

  async Counter()
  {
    this.cookieService.set('AsAlreadyVisitSite', 'true', {expires: 1/144});
    await this.counterService.getCounter();
    this.counterService.incrementCounter();
    this.counterService.saveCounter();
  }

  ngOnInit(): void {
  }

}
