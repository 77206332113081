import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { HouseComponent } from './house/house.component';
import { ModelComponent } from './model/model.component';
import { VideoComponent } from './video/video.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';

import { CounterService } from './services/counter.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LightboxModule } from 'ngx-lightbox';
import { NgxCaptchaModule } from 'ngx-captcha';

const appRoutes: Routes = [
  {path: 'accueil', component: HomeComponent},
  {path: 'maison', component: HouseComponent},
  {path: 'modèles', component: ModelComponent},
  {path: 'tournages', component: VideoComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'mention-legal', component: LegalNoticeComponent},
  {path: '', redirectTo: 'accueil', pathMatch: 'full'},
  {path: '**', redirectTo: 'accueil'}
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HouseComponent,
    HeaderComponent,
    ContactComponent,
    VideoComponent,
    ModelComponent,
    FooterComponent,
    LegalNoticeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled'}),
    BrowserAnimationsModule,
    LightboxModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [CounterService],
  bootstrap: [AppComponent]
})
export class AppModule { }
