<!DOCTYPE html>
<html lang="fr">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="theme-color" content="#e6e6e6"/>
    <meta name="robots" content="all">

    <title>La Maison Shooting, Location région Parisienne, Photos, Photography, Tournages, Clip, Réalisation, Producteur, Vidéos, Modèles photos</title>
    <meta name="description" content="Vous recherchez une maison avec un jardin avec piscine, un studio de photographies à proximité de Paris, pour vos shootings photos, vos tournages, vos clips, vos vidéos, vos publicités et vos films ? La Maison Shooting est faite pour vous.">
</head>

<div id="carouselIndicators" class="carousel slide carousel-dark carousel-fade" data-ride="carousel" data-interval="269000">
    <ol class="carousel-indicators">
        <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselIndicators" data-slide-to="1"></li>
        <li data-target="#carouselIndicators" data-slide-to="2"></li>
        <li data-target="#carouselIndicators" data-slide-to="3"></li>
        <li data-target="#carouselIndicators" data-slide-to="4"></li>
    </ol>
    <div class="carousel-inner" role="listbox" tabindex="0" id="listbox1" aria-activedescendant="listbox1-1">
        <div role="option" id="listbox1-1" class="carousel-item carousel-img-1 active" data-interval="7000">
          <div class="carousel-caption carousel-caption-1 text-center">
            <h1>La Maison Shooting</h1>
          </div>
          <div class="carousel-caption text-center">
            <h2>Maison moderne pour shootings et tournages</h2>
          </div>
        </div>
        <div role="option" id="listbox1-2" class="carousel-item carousel-img-2" data-interval="7000">
          <div class="carousel-caption carousel-caption-2">
            <h2>Décor à la fois contemporain et épuré</h2>
            <h2>avec un bel espace lumineux</h2>
          </div>
        </div>
        <div role="option" id="listbox1-3" class="carousel-item carousel-img-3" data-interval="7000" >
          <div class="carousel-caption carousel-caption-3">
            <h2>Proche de Paris</h2>
          </div>
        </div>
        <div role="option" id="listbox1-4" class="carousel-item carousel-img-4" data-interval="7000" >
          <div class="carousel-caption carousel-caption-4">
            <h2>Jardin avec piscine</h2>
          </div>
        </div>
        <div role="option" id="listbox1-5" class="carousel-item carousel-img-5" data-interval="7000" >
          <div class="carousel-caption carousel-caption-5">
            <h2>Jardin avec piscine</h2>
          </div>
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    </a>
    <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>
